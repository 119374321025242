import React from "react";
import Helmet from "react-helmet";
import { Router } from "@reach/router";
import Remark from "remark";
import toHAST from "mdast-util-to-hast";
import _ from "lodash";
import stripPosition from "unist-util-remove-position";
import hastReparseRaw from "hast-util-raw";
import { graphql } from "gatsby";
import Footer from "../../components/Footer/Footer";
import Post from "../../components/Post/Post";
import Topbar from "../../components/Topbar/Topbar";
import config from "../../../data/SiteConfig";
import Layout from "../../components/layout";

function getAST(markdown) {
  const remark = new Remark().data(`settings`, {
    commonmark: true,
    footnotes: true,
    pedantic: true
  });

  const markdownAST = remark.parse(markdown);
  const ast = toHAST(markdownAST, { allowDangerousHTML: true });
  const strippedAst = stripPosition(_.clone(ast), true);
  return hastReparseRaw(strippedAst);
}

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      post: {},
      categories: []
    };
  }

  componentDidMount() {
    fetch(
      `${config.apiEndpoint}/api/v1/${config.apiPlatformId}/posts/${
        this.props.previewId
      }`,
      { cache: "no-store" }
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            post: {
              slug: result.slug,
              author: result.author,
              dateModified: result.dateModified,
              title: result.title,
              alias: result.alias,
              ogImageId: result.coverId,
              category:
                result.category !== null
                  ? result.category
                  : {
                      title: "Tempo libero",
                      slug: "tempo-libero"
                    },
              subcategory:
                result.subcategory !== null
                  ? result.subcategory
                  : {
                      title: "Libri",
                      slug: "libri"
                    },
              remark: {
                htmlAst: getAST(result.body)
              }
            },
            categories: this.props.categories
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, post, categories } = this.state;

    if (error)
      return (
        <div>
          Error:
          {error.message}
        </div>
      );

    return !isLoaded ? (
      <div>Loading...</div>
    ) : (
      <Layout>
        <Topbar
          title={post.title}
          category={post.category}
          subcategory={post.subcategory}
          categories={categories.edges}
          sharePath={`/${post.slug}/`}
          shareImageId={post.ogImageId}
          shareTitle={post.title}
        />
        <Post post={post} products={[]} />
        <Footer />
      </Layout>
    );
  }
}

class PreviewIndex extends React.Component {
  render() {
    const { categories } = this.props.data;

    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <script>window.isPreview = true;</script>
        </Helmet>
        <Router>
          <Preview path="/preview/:previewId" categories={categories} />
        </Router>
      </React.Fragment>
    );
  }
}

export default PreviewIndex;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostPreview {
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
